import { TFunction } from 'i18next';

import { Finances } from '@typings/finances';

export const useDistrictFinanceMenu = (
  t: TFunction,
  districtId: string,
  isLeader: Boolean
) => {
  const managerDistrictFinanceMenu = [
    {
      label: t('view-invoices', 'View invoices'),
      path: `/district/${districtId}/view-invoices`,
      description: t(
        'view-the-most-recent-invoices',
        'View the most recent invoices for each of your clubs.'
      ),
    },
    {
      label: t('view-club-balance-report', 'View club balance report'),
      path: `/district/${districtId}/view-club-balance-report`,
      description: '',
    },
    {
      label: t('about-the-club-invoice', 'About the club invoice'),
      path: `/manage/club-district-administration/club-invoice`,
      description: '',
    },
  ];

  const viewerDistrictFinanceMenu = [
    {
      label: t('about-the-club-invoice', 'About the club invoice'),
      path: `/manage/club-district-administration/club-invoice`,
      description: '',
    },
  ];

  const data: Finances.MenuResponse = isLeader
    ? managerDistrictFinanceMenu
    : viewerDistrictFinanceMenu;

  return {
    data,
  };
};
