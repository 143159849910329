export const sampleMockData = {
  districtClubs: {
    totalCount: 10,
    clubs: [
      {
        id: '5014fc55-4212-4ec2-9216-54000b8d79f4',
        riClubId: '77606',
        name: 'Amelia Island Sunrise',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 51,
        __typename: 'DistrictClub',
      },
      {
        id: 'a908139c-21ae-4a40-b7e3-94625fa379f4',
        riClubId: '64784',
        name: 'Baker County',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 17,
        __typename: 'DistrictClub',
      },
      {
        id: '507d3f17-6746-4d27-ae56-f6be679879f4',
        riClubId: '63715',
        name: 'Bartram Trail-Julington Creek',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 37,
        __typename: 'DistrictClub',
      },
      {
        id: '384d34e3-16ca-4a9f-8614-6603006079f4',
        riClubId: '4352',
        name: 'Belleview',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 19,
        __typename: 'DistrictClub',
      },
      {
        id: 'b69db25d-d242-4f7c-b705-2220ed8779f4',
        riClubId: '4353',
        name: 'Crescent City',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 28,
        __typename: 'DistrictClub',
      },
      {
        id: '65272a00-fb73-4200-8778-a78c597e79f4',
        riClubId: '4354',
        name: 'Daytona Beach',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 141,
        __typename: 'DistrictClub',
      },
      {
        id: '3df6a7d3-88b4-4f44-872c-f343688079f4',
        riClubId: '27045',
        name: 'Daytona Beach West',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 43,
        __typename: 'DistrictClub',
      },
      {
        id: '93000e40-41f8-4749-9b35-ba54ac7579f4',
        riClubId: '4356',
        name: 'Debary-Deltona-Orange City',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 36,
        __typename: 'DistrictClub',
      },
      {
        id: '7735bb9c-a5a1-479f-8d5b-ab689f7279f4',
        riClubId: '23604',
        name: 'Deerwood Jacksonville',
        type: 'RotaryClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 46,
        __typename: 'DistrictClub',
      },
      {
        id: '965e13e1-a659-4c10-9c7d-5ebbb7e17f2d',
        riClubId: '216365',
        name: 'Deland',
        type: 'RotaractClub',
        status: 'Active',
        location: {
          country: 'United States',
          state: 'Florida',
          countryId: 'USA',
          city: null,
          stateId: 'FL',
          internationalProvince: null,
          __typename: 'LocationData',
        },
        activeMembersCount: 34,
        __typename: 'DistrictClub',
      },
    ],
    __typename: 'DistrictClubsResults',
  },
};
