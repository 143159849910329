import React from 'react';

import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props {
  isManager: boolean;
}

const ViewClubBalanceReport: React.FC<Props> = ({ isManager }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  if (!isManager) {
    return <AccessDenied />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.view-club-balance-report',
          'View Club Balance Report'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <div className="mt-12">View Club Balance Report</div>
    </>
  );
};

export default ViewClubBalanceReport;
