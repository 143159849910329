import LZString from 'lz-string';

import { AppData, AppKeyRoledata } from './groupapplicationrolesrelationships';

export const hasFinanceAccess = () => {
  const userAppsContextString = window.localStorage.getItem(
    'compressedUserAppData'
  );
  let userAppsContext: AppData | null = null;
  if (userAppsContextString && userAppsContextString.length > 0) {
    userAppsContext = JSON.parse(
      LZString.decompressFromEncodedURIComponent(userAppsContextString)
    );
  }
  const oktaAppIds = new Set(
    Object.keys(userAppsContext?.appData as AppKeyRoledata)
  );
  const districtFinanceAppOktaIds = [
    process.env.GATSBY_VIEW_INVOICES_DISTRICT_OKTA_APP_ID as string,
    process.env.GATSBY_DAILY_CLUB_BALANCE_DISTRICT_OKTA_APP_ID as string,
  ];
  const hasFinanceAccess = districtFinanceAppOktaIds.every(key =>
    oktaAppIds.has(key)
  );
  return hasFinanceAccess;
};
