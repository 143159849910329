module.exports = {
  theme: {
    // Font sizes / line heights from designs:
    //                SIZE IN PX    SIZE IN TAILWIND
    // Body small     14px / 21px   xs / xs
    // Mobile title   18px / 25px   2md / 2md
    // Body           16px / 24px   md / md
    // Body large     20px / 35px   lg / lg
    // alternate H5   13px / 21px   2xs / 2xs
    // H5             14px / 17px   xs / xs-heading
    // alternate H4   15px / 20px   sm / sm
    // H4             16px / 28px   md / md-heading
    // H3             20px / 27px   lg / lg-heading
    // H2             36px / 49px   xl / xl
    // H1 (mobile)    36px / 49px   xl / xl
    // H1             66px / 90px   2xl / 2xl
    fontSize: {
      inherit: 'inherit',
      '4xs': '0.6875rem', //   11px
      '3xs': '0.75rem', //   12px
      '2xs': '0.8125rem', // 13px
      xs: '0.875rem', //     14px
      sm: '0.9375rem', //    15px
      md: '1rem', //         16px
      '2md': '1.125rem', //  18px
      lg: '1.25rem', //      20px
      'lg-18': '1.125rem', // 18px
      'lg-22': '1.375rem', // 22px
      'lg-24': '1.5rem', //  24px
      '2lg': '1.75rem', //   28px
      '3lg': '2rem', //     32px
      '4lg': '2.15rem', //   34px
      xl: '2.25rem', //      36px
      'xl-38': '2.375rem', // 38px
      'xl-40': '2.5rem', //   40px
      'xl-48': '3rem', // 48px
      'xl-58': '3.625rem', // 58px
      '2xl': '4.125rem', //  66px
    },
    lineHeight: {
      inherit: 'inherit',
      '3xs': '0.75rem', //   12px
      '2xs': '1.3125rem', //        21px
      xs: '1.3125rem', //           21px
      'xs-heading': '1.0625rem', // 17px
      sm: '1.25rem', //             20px
      md: '1.5rem', //              24px
      '2md': '1.5625rem', //        25px
      'md-heading': '1.75rem', //   28px
      'md-30': '1.875rem', //       30px
      '3md': '2.0625', //           33px
      lg: '2.1875rem', //           35px
      'lg-36': '2.25rem', //        36px
      lgx: '2.375rem', //           38px
      'lg-heading': '1.6875rem', // 27px
      'lg-43': '2.6875rem', //      43px
      'lg-45': '2.8125rem', //      45px
      'xl-48': '3rem', //           48px
      xl: '3.0625rem', //           49px
      'xl-57': '3.5625rem', //      57px
      'xl-58': '3.625rem', //       58px
      'xl-70': '4.375rem', //       70px
      '2xl': '5.625rem', //         90px
      none: 0,
      tight: 0.9,
      normal: 1,
      relaxed: 1.1,
      cozy: 1.2,
      loose: 1.3,
      // Naming follows https://tailwindcss.com/docs/line-height, "unit" is 0.25.
      6: 1.5,
      7: 1.75,
      8: 2,
      10: '2.5rem',
    },
    colors: {
      current: 'currentColor',
      transparent: 'rgba(0, 0, 0, 0)',
      black: '#000000',
      white: '#ffffff',
      azure: '#0050A2',
      dblue: '#17458F',
      gray: {
        '50': '#c4bdce',
        '100': '#f7f7f7',
        '200': '#edeeef',
        '250': '#ededef',
        '275': '#dbdbdc',
        '300': '#bec4c9',
        '350': '#cccccc',
        '300-60': 'rgba(190, 196, 201, 0.6)',
        '400': '#878d90',
        '500': '#5e717d',
        '600': '#39424a',
        '650': '#E7E7E8',
        '700': '#263B4C',
      },
      'naturals-gray-600': '#323c44',
      'grey-light': '#92A8CB',
      'bright-blue': {
        '100': '#f1fcff',
        '200': '#d1f2fc',
        '300': '#bbefff',
        '300-50': '#035DA9',
        '400': '#88d6f1',
        '400-20': 'rgba(32, 187, 230, 0.2)',
        '500': '#20bbe6',
        '600': '#019fcb',
        '600-50': 'rgba(1, 159, 203, 0.5)',
        '600-60': '#00346b',
      },
      'dark-blue': {
        '100': '#B2D4FF',
        '200': '#8ca8ce',
        '300': '#177a97',
        '400': '#19519c',
        '500': '#0c3c7c',
        '600': '#0c3266',
        '600-50': '#00246c',
        '600-60': '#005DAA',
      },
      purple: {
        '100': '#f4dcef',
        '200': '#e4c0dd',
        '600': '#872175',
      },
      green: {
        '100': '#f0f7ea',
        '300': '#99cc72',
        '600': '#017474',
      },
      red: {
        '100': '#fae1de',
        '300': '#db908b',
        '500': '#c10042',
        '600': '#b82216',
      },
      gold: {
        '500': '#f6be5a',
        '500-20': 'rgba(246, 190, 90, 0.2)',
        '600': '#f7a81b',
      },
      orange: {
        '400': '#FEBD11',
        '500': '#fec122',
      },
    },
    screens: {
      mobile: '260px', //          => @media (min-width: 260px) { ... }
      'mobile-m': '375px', //      => @media (min-width: 375px) { ... }
      'mobile-l': '576px', //      => @media (min-width: 576px) { ... }
      tablet: '768px', //          => @media (min-width: 768px) { ... }
      'tablet-l': '846px', //      => @media (min-width: 846px) { ... }
      desktop: '992px', //         => @media (min-width: 992px) { ... }
      'desktop-s': '1036px', //    => @media (min-width: 1036px) { ... }
      'desktop-s-m': '1081px', //  => @media (min-width: 1081px) { ... }
      'desktop-m': '1200px', //    => @media (min-width: 1200px) { ... }
      'desktop-l': '1440px', //    => @media (min-width: 1440px) { ... }
      'desktop-custom-delegation1': { min: '1344px', max: '1439px' },
      'desktop-custom-delegation2': { min: '1317px', max: '1343px' },
      'desktop-custom-delegation3': { min: '1175px', max: '1199px' },
    },
    borderWidth: {
      DEFAULT: '1px',
      '0': '0',
      '2': '2px',
      '3': '3px',
      '4': '4px',
      '6': '6px',
      '8': '8px',
    },
    borderRadius: {
      none: '0',
      '1px': '1px',
      sm: '.125rem',
      DEFAULT: '.25rem',
      lg: '.5rem',
      full: '9999px',
      xlg: '.75rem',
    },
    flex: {
      '1': '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      inherit: 'inherit',
      none: 'none',
      '2': '2 2 0%',
      '3': '3 3 0%',
    },
    extend: {
      minWidth: {
        '9': '9.35rem',
        '14': '14rem',
        '16': '16rem',
        '20': '20rem',
        '24': '24rem',
        '32': '32rem',
        '38': '38rem',
        '230': '14.375rem',
      },
      maxWidth: {
        '7xl': '90rem',
        '80': '20rem',
        '230': '14.375rem',
      },
      minHeight: {
        '12': '3rem',
        '16': '4rem',
        '20': '5.125rem',
        '25': '6.25rem',
        '50': '3.125rem',
      },
      width: {
        '7xl': '90rem',
        '230': '14.375rem',
        '120': '30rem',
        '40pct': '40%',
        '50pct': '50%',
        '60pct': '60%',
        '64pct': '64%',
        '29': '114px',
        '250': '15.625rem',
        '330': '20.625rem',
      },
      height: {
        '60pct': '60%',
        '50': '3.125rem',
      },
      maxHeight: {
        '9': '9.35rem',
        '72': '18rem',
        '75': '18.75rem',
        '96': '24rem',
      },
      padding: {
        // 74rem = 90rem (7xl) - 16rem (px-32)
        'center-two-columns': 'calc(calc(100vw - 74rem) / 2)',
        'p-5': '5%',
        '3px': '3px',
        '5px': '5px',
        '15px': '0.9375rem',
        '20px': '1.25rem',
        '21': '5.25rem',
      },
      spacing: {
        '0.5': '.125rem',
        '1': '.25rem',
        '2': '.5rem',
        '0625': '0.625rem',
        '3': '.75rem',
        '7/2': '14px',
        '13/2': '1.625rem',
        '7': '1.75rem',
        '1875': '1.875rem',
        '9': '2.25rem',
        '11': '2.75rem',
        '13': '3.25rem',
        '14': '3.5rem',
        '15': '3.75rem',
        '17': '4.25rem',
        '18': '4.5rem',
        '19': '4.75rem',
        '25': '6.25rem',
        '30': '7.5rem',
        '45': '11.25rem',
        '74': '18.5rem',
        '88': '22rem',
        '92': '23rem',
        '1.5': '0.375rem',
        '31': '1.9375rem',
      },
      inset: {
        init: 'initial',
        'header-mobile': '60px',
        'header-desktop': '195px',
        '1': '0.25rem',
        '8': '2rem',
        '0625': '0.625rem',
      },
      letterSpacing: {
        'wide-light': '0.01em',
      },
      zIndex: {
        '-1': -1,
        '0': 0,
        '60': 60,
      },
      margin: {
        '-2': '-2px',
        '68': '4.25rem', // 68px
        '3px': '3px',
        '5px': '5px',
      },
      gap: {
        '15': '3.75rem', // 60px
      },
    },
  },
  purge: false,
  content: ['./src/**/*.{tsx,css}'],
};
