import React, { useState } from 'react';

import Button from '@rotaryintl/harris-button';
import RadioField from '@rotaryintl/harris-form-basic/dist/RadioField/RadioField';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import Pagination from '@rotaryintl/harris-pagination';
import Table from '@rotaryintl/harris-table';
import { Form, Formik } from 'formik';

import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';
import NoResultFound from '@presenters/web/components/NoResultsFound/NoResultFound';

import { sampleMockData as dataSet } from './mockData';
import './styles.css';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useLogger } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

interface Props {
  isManager: boolean;
}

const ViewInvoices: React.FC<Props> = ({ isManager }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // pagination logic for mock data.
  const data = dataSet.districtClubs.clubs;
  const pageSize = 5;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(
    startIndex + pageSize,
    dataSet.districtClubs.totalCount
  );
  const currentPageData = data.slice(startIndex, endIndex);
  // pagination logic ends

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const { addLog } = useLogger();

  const submitHandler = (values: { radioField: string; search: string }) => {
    setSearchTerm(values.search);
  };

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  if (!isManager) {
    return <AccessDenied />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.view-invoices',
          'View Invoices'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <div>
        <Button
          clickHandler={() => {}}
          icon="fas-angle-left"
          size="sm"
          variant="text"
          className="mt-4"
        >
          {t('view-invoices.back', 'Back')}
        </Button>
        <h2 className="pt-6 font-semibold text-xl-38 tablet:text-xl-58">
          Club Invoices
        </h2>

        <Formik
          initialValues={{
            radioField: 'Club name',
            search: '',
          }}
          onSubmit={submitHandler}
          css="my-8"
        >
          {({ values, submitForm, resetForm }) => (
            <Form>
              <RadioField
                label={t(
                  'view-invoices.choose-club-search-type-radio-label',
                  'Choose club search type'
                )}
                name="radioField"
                options={[
                  {
                    label: t(
                      'view-invoices.club-name-radio-option-label-1',
                      'Club name'
                    ),
                    value: t(
                      'view-invoices.club-name-radio-option-value-1',
                      'Club name'
                    ),
                  },
                  {
                    label: t(
                      'view-invoices.club-id-radio-options-lable-2',
                      'Club ID'
                    ),
                    value: t(
                      'view-invoices.club-name-radio-option-value2',
                      'Club ID'
                    ),
                  },
                ]}
              />
              <TextField
                label={
                  values.radioField ===
                  t('view-invoices.club-name-radio-option-value-1', 'Club name')
                    ? t(
                        'view-invoices.club-name-radio-option-label-1',
                        'Club name'
                      )
                    : t(
                        'view-invoices.club-name-radio-option-value2',
                        'Club ID'
                      )
                }
                name="search"
                placeholder={`${t(
                  'view-invoices.search-by-placeholder',
                  'Search by'
                )} ${values.radioField}`}
                icon="search"
                size="md"
                type="search"
                className="leading-7 font-semibold placeholder:font-normal w-full tablet:w-4/12"
              />
              <div className="flex flex-row gap-4 pb-2">
                <Button clickHandler={submitForm}>
                  {t('view-invoices.search', 'Search')}
                </Button>
                {values.search && (
                  <Button
                    clickHandler={() => {
                      resetForm();
                      setSearchTerm('');
                    }}
                    size="md"
                    variant="text"
                  >
                    {t('view-invoices.reset', 'Reset')}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>

        {currentPageData.length > 0 ? (
          <>
            <div className="text-md font-semibold py-4 tablet:py-6 border-b tablet:border-0 border-gray-500">
              {t('view-invoices.club-invoices', 'Club Invoices')} (
              {dataSet?.districtClubs?.totalCount ?? 0})
            </div>
            <Table
              id="DistrictInvoiceTable"
              bodyRows={currentPageData.map(data => ({
                cells: [
                  {
                    text: (
                      <span className="font-normal">
                        {`${data.name}, ${data.location.state}, ${data.location.country}`}
                        `
                      </span>
                    ),
                  },
                  {
                    text: <span>{data.type}</span>,
                  },
                  {
                    text: <span>{data.riClubId}</span>,
                  },
                  {
                    text: (
                      <span className="text-dark-blue-500 flex flex-col">
                        <span
                          className="font-semibold hover:underline hover:cursor-pointer"
                          onClick={() => {
                            addLog({
                              level: LogLevel.Info,
                              message: `Club id is ${data.id}, Club type is ${data.type}`,
                            });
                          }}
                        >
                          {t(
                            'view-invoices.current-invoice',
                            'Current Invoice'
                          )}
                        </span>
                        <span
                          className="font-semibold hover:underline hover:cursor-pointer pt-2"
                          onClick={() => {
                            addLog({
                              level: LogLevel.Info,
                              message: `Club id is ${data.id}, Club type is ${data.type}`,
                            });
                          }}
                        >
                          {t(
                            'view-invoices.current-invoice-details',
                            'Current Invoice Details'
                          )}
                        </span>
                      </span>
                    ),
                  },
                  {
                    text: (
                      <div className="text-dark-blue-500">
                        <div
                          className="font-semibold hover:underline hover:cursor-pointer"
                          onClick={() => {
                            addLog({
                              level: LogLevel.Info,
                              message: `Club id is ${data.id}, Club type is ${data.type}`,
                            });
                          }}
                        >
                          {t(
                            'view-invoices.previous-invoice',
                            'Previous Invoice'
                          )}
                        </div>
                        <div
                          className="font-semibold hover:underline hover:cursor-pointer pt-2"
                          onClick={() => {
                            addLog({
                              level: LogLevel.Info,
                              message: `Club id is ${data.id}, Club type is ${data.type}`,
                            });
                          }}
                        >
                          {t(
                            'view-invoices.previous-invoice-details',
                            'Previous Invoice Details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                ],
              }))}
              bodyStyle="default"
              headerStyle="light"
              headerRow={{
                cells: [
                  {
                    text: (
                      <div className="cell-label">
                        {t('view-invoices.club-name', 'Club Name')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="cell-label">
                        {t('view-invoices.club-type', 'Club Type')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="cell-label">
                        {t('view-invoices.id', 'ID')}
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="flex-col items-baseline mt-5 hidden tablet:flex">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'view-invoices.current-invoice',
                            'Current Invoice'
                          )}{' '}
                          /
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'view-invoices.invoice-details',
                            'Invoice Details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div className="hidden tablet:flex flex-col items-baseline mt-5">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'view-invoices.previous-invoice',
                            'Previous Invoice'
                          )}{' '}
                          /
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'view-invoices.invoice-details',
                            'Invoice Details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                ],
              }}
            />
            <div className="py-6">
              <Pagination
                currentPage={currentPage}
                lang="en"
                onPageChange={onPageChange}
                pageSize={pageSize}
                siblingCount={1}
                totalCount={dataSet?.districtClubs?.totalCount || 0}
                variant="Dropdown"
              />
            </div>
          </>
        ) : (
          <NoResultFound searchParams={searchTerm} />
        )}
      </div>
    </>
  );
};

export default ViewInvoices;
