import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import { isNull } from 'lodash';

import Element, { ElementProps } from './Element';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';
import ReactSelect, {
  components,
  IndicatorProps,
  MenuListComponentProps,
  OptionProps,
} from '@external/react-select';

import { MultiSelectFilterState } from './MultiSelectFilter/types';
import { ClubTypeFilterValue } from '@typings/graphql';

const getControlBackgroundStyle = (state: MultiSelectFilterState) => {
  const { isDisabled, selectProps } = state;
  if (isDisabled) {
    return tailwind.theme.colors.gray['100'];
  }
  if (selectProps.blueBg) {
    return tailwind.theme.colors['bright-blue']['100'];
  }
  return tailwind.theme.colors.white;
};

const getOptionBackgroundStyle = (state: MultiSelectFilterState) => {
  const { isSelected, isFocused } = state;
  if (isSelected) {
    return tailwind.theme.colors['bright-blue']['600'];
  }
  if (isFocused) {
    return tailwind.theme.colors.gray['200'];
  }
  return tailwind.theme.colors.white;
};

const styles = (error: boolean) => ({
  control: (provided: CSSProperties, state: MultiSelectFilterState) => {
    const background = getControlBackgroundStyle(state);
    return {
      ...provided,
      border: `1px solid ${
        error
          ? tailwind.theme.colors.red['600']
          : tailwind.theme.colors.gray['300']
      }`,
      borderRadius: '.125rem',
      padding: '0.1875rem 0.5rem',
      background,
      boxShadow: 'none',
      '&:hover, &:focus': {
        borderColor: tailwind.theme.colors['dark-blue'][400],
      },
    };
  },
  menu: (provided: CSSProperties) => ({
    ...provided,
    marginTop: '1px',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    border: 0,
    maxHeight: '18.75rem',
  }),
  placeholder: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    color: state.isDisabled
      ? tailwind.theme.colors.gray['300']
      : tailwind.theme.colors.gray['400'],
  }),
  dropdownIndicator: (
    provided: CSSProperties,
    state: MultiSelectFilterState
  ) => ({
    ...provided,
    color: state.isDisabled
      ? tailwind.theme.colors.gray['300']
      : tailwind.theme.colors['bright-blue']['600'],
  }),
  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    backgroundColor: tailwind.theme.colors.gray['300'],
    marginTop: '6px',
    marginBottom: '6px',
    marginLeft: '8px',
  }),
  option: (provided: CSSProperties, state: MultiSelectFilterState) => ({
    ...provided,
    background: getOptionBackgroundStyle(state),
  }),
  clearIndicator: () => ({
    lineHeight: '1',
    background: tailwind.theme.colors.gray['300'],
    color: tailwind.theme.colors.white,
    borderRadius: '100%',
  }),
});

export interface SelectProps extends ElementProps {
  /**
   * Placeholder text to be displayed when empty.
   */
  placeholder?: string;
  /**
   * Visually move the label inside the dropdown menu.
   */
  dropdownLabel?: boolean;
  /**
   * Set the element to be searchable. Enforced for dynamic options.
   */
  searchable?: boolean;
  /**
   * Display a clear icon.
   */
  clearable?: boolean;
  /**
   * Select options. Either a hardcoded list of `{value: '', label: ''}` or a
   * hook that provides these.
   */
  options: SelectOptions | useOptions;
  /**
   * Text to display in a tooltip shown when clicking the question mark.
   */
  tooltipText?: string;
  /**
   * Display a light blue background color. That color is associated with permissions in the UI.
   */
  blueBg?: boolean;
  /**
   * Additional CSS classes.
   */
  additionalClasses?: string;
  /**
   * Listen to change event
   */
  onChange?: (newValue: string, options?: SelectOptions | useOptions) => void;

  /**
   * Optional value for the select field, if this has to be managed from outside
   * of the component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;

  /**
   * Optional prop for the club select field, enables filtering by the selected district
   */
  riDistrictId?: number;

  /**
   * Whether select is a required field
   */
  required?: boolean;
  notice?: string;
  filterOption?: ((option: SelectOption, rawInput: string) => boolean) | null;
  isCountryFlagRequired?: boolean;
  loading?: boolean;
  clubType?: ClubTypeFilterValue | null | string;
}

export type SelectOptions = SelectOption[];

export type useOptionsResult = {
  options?: SelectOptions;
  loading?: boolean;
  error?: string;
};

export type useOptions = (
  input: string,
  riDistrictId?: number,
  clubType?: ClubTypeFilterValue | null | string
) => useOptionsResult;

function isDynamic(options: SelectOptions | useOptions): options is useOptions {
  return options instanceof Function;
}

function useOptionsHook(
  options: SelectOptions | useOptions,
  input: string,
  riDistrictId?: number,
  name?: string,
  clubType?: ClubTypeFilterValue | null | string
): useOptionsResult {
  if (isDynamic(options)) {
    return riDistrictId && name && name.includes('club')
      ? options(input, riDistrictId, clubType)
      : options(input);
  }
  return { options };
}

export type SelectOption = {
  label: string | JSX.Element;
  value: string;
  type?: string | null;
  isDisabled?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSelectOption(option: any): option is SelectOption {
  return (
    option &&
    Object.prototype.hasOwnProperty.call(option, 'label') &&
    Object.prototype.hasOwnProperty.call(option, 'value')
  );
}

const Select: React.FC<SelectProps> = props => {
  const {
    name,
    options: propsOptions,
    riDistrictId,
    dropdownLabel,
    label,
    blueBg,
    additionalClasses,
    disabled,
    searchable,
    clearable,
    placeholder,
    value,
    onChange,
    filterOption,
    isCountryFlagRequired = false,
    loading: externalLoading,
    clubType,
  } = props;
  const { t } = useTranslation();
  const [field, meta, helpers] = useField<string>(name);
  const [input, setInput] = useState<string>('');
  const [current, setCurrent] = useState<SelectOption | null>(null);
  const { options, loading, error } = useOptionsHook(
    propsOptions,
    input,
    riDistrictId,
    name,
    clubType
  );

  useEffect(() => {
    if (current && current.value === field.value) {
      return;
    }
    if (!field.value) {
      setCurrent(null);
    } else if (options) {
      setCurrent(options.find(op => op.value === field.value) || null);
    }
  }, [field, current, options]);

  const MenuList = useCallback(
    (menuProps: MenuListComponentProps<SelectOption>) => (
      <components.MenuList {...menuProps}>
        <div className="form-select-options">
          {dropdownLabel && (
            <label
              aria-hidden
              className="normal-case text-gray-300 font-bold text-xs px-3 py-3 block"
              htmlFor={name}
            >
              {label}
            </label>
          )}
        </div>
        {menuProps.children}
      </components.MenuList>
    ),
    []
  );

  const Option = useCallback(
    (optionProps: OptionProps<SelectOption>) => (
      <div className="form-select-option">
        <components.Option {...optionProps} />
      </div>
    ),
    []
  );

  const ClearIndicator = useCallback(
    (indicatorProps: IndicatorProps<SelectOption>) => (
      <div className="form-select-clear">
        <components.ClearIndicator {...indicatorProps} />
      </div>
    ),
    []
  );

  const helpersRef = useRef(helpers);
  useEffect(() => {
    if (error) {
      helpersRef.current.setError(error);
    }
  }, [helpersRef, error]);

  return (
    <Element labelHidden={dropdownLabel} {...props}>
      <ReactSelect
        inputId={name}
        name={name}
        blueBg={blueBg}
        className={classNames(
          `form-select Select text-xs ${additionalClasses || ''}`,
          {
            'cursor-not-allowed': disabled,
          }
        )}
        isSearchable={searchable || isDynamic(propsOptions)}
        isLoading={loading || externalLoading}
        isClearable={clearable}
        styles={styles(
          (!!meta.error && meta.touched) ||
            !!error ||
            (isCountryFlagRequired && isNull(current))
        )}
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        value={(typeof value !== 'undefined' && value) || current}
        noOptionsMessage={() =>
          t('forms.select.no-options-default', 'No results')
        }
        onChange={op => {
          setCurrent(isSelectOption(op) ? op : null);
          helpers.setValue(isSelectOption(op) ? op.value : '');
          onChange?.(isSelectOption(op) ? op.value : '', options);
        }}
        onInputChange={setInput}
        components={{
          MenuList,
          Option,
          ClearIndicator,
        }}
        filterOption={filterOption}
      />
    </Element>
  );
};

export default Select;
